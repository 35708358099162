document.querySelector('.header > button').addEventListener('click', () => {
	if (!document.querySelector('body').classList.contains('navigation-menu-is-open')) {
		document.querySelector('body').classList.add('navigation-menu-is-open');
		setTimeout(() => {
			document.querySelector('.navigation-menu').style.opacity = 1;
		}, 100);
	} else {
		document.querySelector('.navigation-menu').style.opacity = 0;
		setTimeout(() => {
			document.querySelector('body').classList.remove('navigation-menu-is-open');
		}, 500);
	}
});
