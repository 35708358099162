const featuredSlider = tns({
	container: '.featured-slider__content',
	mode: 'gallery',
	items: 1,
	slideBy: 'page',
	loop: true,
	nav: true,
	controls: true,
	lazyload: true,
	touch: false,
	mouseDrag: false,
	autoplay: false,
	speed: 0,
	controlsText: ['Vorige', 'Volgende'],
	animateIn: 'is-being-showed',
	animateOut: 'is-being-hidden',
	responsive: {
		1024: {
			speed: 1000,
		},
	},
});
