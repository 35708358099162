const filterLinks = document.getElementsByClassName('js-filter-bar-link');

function hideAllWork() {
	const hideItems = document.querySelectorAll('.loop--work .loop__item');
	for (let i = 0; i < hideItems.length; i++) {
		hideItems[i].classList.add('is-hidden');
		const hideTO = setTimeout(() => {
			hideItems[i].style.display = 'none';
		}, 200);
	}
}

function showWork(data) {
	hideAllWork();
	const showItems = (data === 'all') ? document.querySelectorAll('.loop--work .loop__item') : document.querySelectorAll(`.loop--work .loop__item[data-filter="${data}"]`);
	const showTO = setTimeout(() => {
		for (let i = 0; i < showItems.length; i++) {
			showItems[i].style.display = 'block';
			const opacityTO = setTimeout(() => {
				showItems[i].classList.remove('is-hidden');
			}, 200);
		}
	}, 200);
}

function updateFilter(data) {
	document.querySelector('.js-filter-bar-link.is-active').classList.remove('is-active');
	document.querySelector(`.js-filter-bar-link[data-filter="${data}"]`).classList.add('is-active');
}

Array.from(filterLinks).forEach((filterLink) => {
	const filterData = filterLink.getAttribute('data-filter');
	filterLink.addEventListener('click', (event) => {
		event.preventDefault();
		if (!filterLink.classList.contains('is-active')) {
			showWork(filterData);
			updateFilter(filterData);
		}
	});
});

// Production steps of ECMA-262, Edition 5, 15.4.4.18
// Reference: http://es5.github.io/#x15.4.4.18
if (!Array.prototype.forEach) {
	Array.prototype.forEach = function (callback/* , thisArg */) {
		let T,
			k;

		if (this == null) {
			throw new TypeError('this is null or not defined');
		}

		// 1. Let O be the result of calling toObject() passing the
		// |this| value as the argument.
		const O = Object(this);

		// 2. Let lenValue be the result of calling the Get() internal
		// method of O with the argument "length".
		// 3. Let len be toUint32(lenValue).
		const len = O.length >>> 0;

		// 4. If isCallable(callback) is false, throw a TypeError exception. 
		// See: http://es5.github.com/#x9.11
		if (typeof callback !== 'function') {
			throw new TypeError(`${callback} is not a function`);
		}

		// 5. If thisArg was supplied, let T be thisArg; else let
		// T be undefined.
		if (arguments.length > 1) {
			T = arguments[1];
		}

		// 6. Let k be 0.
		k = 0;

		// 7. Repeat while k < len.
		while (k < len) {
			var kValue;

			// a. Let Pk be ToString(k).
			//    This is implicit for LHS operands of the in operator.
			// b. Let kPresent be the result of calling the HasProperty
			//    internal method of O with argument Pk.
			//    This step can be combined with c.
			// c. If kPresent is true, then
			if (k in O) {
				// i. Let kValue be the result of calling the Get internal
				// method of O with argument Pk.
				kValue = O[k];

				// ii. Call the Call internal method of callback with T as
				// the this value and argument list containing kValue, k, and O.
				callback.call(T, kValue, k, O);
			}
			// d. Increase k by 1.
			k++;
		}
		// 8. return undefined.
	};
}
