const photoSlider = tns({
	container: '.photo-slider',
	mode: 'gallery',
	items: 1,
	slideBy: 'page',
	loop: true,
	nav: true,
	touch: false,
	mouseDrag: false,
	autoplay: false,
	speed: 0,
	controlsText: ['Vorige', 'Volgende'],
});
