class MarqueeItem extends HTMLElement {
	get disabled() {
		return this.hasAttribute('disabled');
	}

	set disabled(val) {
		if (val) {
			this.setAttribute('disabled', '');
		} else {
			this.removeAttribute('disabled');
		}
	}

	constructor() {
		super();
		this.init();
	}

	init() {
		if (this.doSetup()) {
			this.doAnimation();
		}
	}

	doSetup() {
		this.steps = 0;
		this.speed = this.getAttribute('data-animation-speed');
		this.elWidth = Math.round((this.querySelector('span').offsetWidth / this.parentElement.offsetWidth) * 100);
		const children = Array.from(this.querySelectorAll('span'));
		for (const child of children) {
			child.style.width = `${Math.round(this.parentElement.offsetWidth * (this.elWidth / 100))}px`;
		}

		return true;
	}

	doAnimation() {
		if (!this.disabled) {
			if (this.steps < (this.elWidth - (this.speed / 100))) {
				this.steps += (this.speed / 100);
				this.style.marginLeft = `-${this.steps}%`;
				requestAnimationFrame(this.doAnimation.bind(this));
			} else {
				this.steps = 0;
				requestAnimationFrame(this.doAnimation.bind(this));
			}
		}
	}
}

window.onload = function initMarqueeItem() {
	window.customElements.define('marquee-item', MarqueeItem);
};
